import React, { useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../../../App'
import CreateTelegramTask from '../../elements/createTelegramTask/createTelegramTask'
import './telegramServices.css'


const TelegramServices = () => {
    const user = useContext(UserContext)
    const navigate = useNavigate()
    const [redirectLink, setRedirectLink] = useState(null)
    const [mailingCreate, setMailingCreate] = useState(false)
    const [type, setType] = useState(null)

    const fon = useRef(null)
    const trainingBanner = useRef(null)


    function closeTraining() {
        localStorage.setItem('training', 'true')
        if (redirectLink === '/cabinet/telegram_mailing') {
            fon.current.style.display = 'none'
            trainingBanner.current.style.display = 'none'
            setMailingCreate(true)
        }
        else if (redirectLink === '/cabinet/telegram_inviting') {
            fon.current.style.display = 'none'
            trainingBanner.current.style.display = 'none'
            setMailingCreate(true)
        }
        else if (redirectLink === '/cabinet/telegram_parser') {
            fon.current.style.display = 'none'
            trainingBanner.current.style.display = 'none'
            setMailingCreate(true)
        }
    }
    function redirectTraining() {
        navigate('/cabinet/training')
        localStorage.setItem('training', 'true')
    }

    function redirectFunc(link) {
        setRedirectLink(link)
        if (link === '/cabinet/telegram_mailing') {
            setType('mailing')
        }
        else if (link === '/cabinet/telegram_inviting') {
            setType('inviting')
        }
        else if (link === '/cabinet/telegram_parser') {
            setType('parser')
        }        
        if (user['tarif'] && !localStorage.getItem('training')) {
            fon.current.style.display = 'flex'
            trainingBanner.current.style.display = 'flex'
            

        } else {
            setMailingCreate(true)
        }
    }


    return (
        <>
            <CreateTelegramTask type={type} active={mailingCreate} setMailingCreate={setMailingCreate} />
            <div ref={fon} className='formResponceFon'></div>
            <div ref={trainingBanner} className='trainingBanner'>
                <p>Хотите пройти обучение?</p>
                <span onClick={closeTraining}>НЕТ</span>
                <button onClick={redirectTraining}>ДА</button>
            </div>

            <div className='telegramServiceDiv'>
                <div onClick={() => redirectFunc('/cabinet/telegram_mailing')} className="tgSer tgSerMailing">
                    <div className='tgSerHover tgSerMailingHover tgSerMailingHover'></div>
                    <img src="/static/img/cabinet/mailing.png" alt="" />
                    {user['tarif'] ?
                        <p>Рассылка сообщений</p>
                        :
                        <p>Рассылка сообщений <span>(демо)</span></p>
                    }
                    <div className="shadowElipse"></div>
                </div>
                <div className='telegramServiceCase'>
                    <div onClick={() => redirectFunc('/cabinet/telegram_inviting')} className="tgSer tgSerInvite">
                        <div className='tgSerHover tgSerInviteHover tgSerInviteHover'></div>
                        <img src="/static/img/cabinet/invite.png" alt="" />
                        {user['tarif'] ?
                            <p>Инвайтинг</p>
                            :
                            <p>Инвайтинг <span>(демо)</span></p>
                        }
                        <div className="shadowElipse"></div>
                    </div>
                    <div onClick={() => redirectFunc('/cabinet/telegram_parser')} className="tgSer tgSerParser">
                        <div className='tgSerHover tgSerParserHover tgSerParserHover'></div>
                        <img src="/static/img/cabinet/parser.png" alt="" />
                        {user['tarif'] ?
                            <p>Парсер польлзователей</p>
                            :
                            <p>Парсер польлзователей <span>(демо)</span></p>
                        }
                        <div className="shadowElipse"></div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default TelegramServices