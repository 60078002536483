import ChatFon from '../../elements/chatFon/ChatFon';
import Mailing from '../../elements/mailing/mailing';
import MetaTags from 'react-meta-tags';

export default function Home() {
    return (
        <>
            <MetaTags>
                <title>Сервис массовой рассылки сообщений в соцсетях и мессенджерах Baksbo.ru</title>
                <meta name="description" content="Baksbo.ru – это сервис массовой рассылки сообщений в соцсетях и мессенджерах по всему миру. Работаем по готовым базам, а также собираем базу контактов с нуля."></meta>
                <meta name="keywords" content="сервис массовой рассылки сообщений, сервис рассылки сообщений в соцсетях, сервис рассылки сообщений в мессенджерах, массовая рассылка сообщений в соцсетях, рассылка сообщений по всему миру, baksbo ru."></meta>
                {/* <meta property="og:image" content="/static/img/icons/home.png"></meta> */}
            </MetaTags>
            <ChatFon />
            <div className="eclipse"></div>
            <div className='homeHeader'>
                <div className="homeHeaderText">
                    <h1>Рассылка сообщений</h1>
                    <h2>в соцсети и мессенджеры</h2>
                    <p>Продавайте свой продукт целевой аудитории гораздо быстрее и качественней, <i>каждое сообщение доходит до клиента с уведомлением.</i></p>
                    <div className='homeHeaderButtonDiv'>
                        <a href="#freeConsultation">
                            <button className='getPresent'>Консультация</button>
                        </a>
                        {/* <a href="#selfMailing">
                            <button className='selfMailig'>Самостоятельная рассылка</button>
                        </a> */}
                        <div className="headerSocialLinks">
                            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                                <img src="static/img/icons/insta.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                                <img src="static/img/icons/tg.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                                <img src="static/img/icons/waapp.png" alt="" />
                            </a>

                            <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                                <img src="static/img/icons/vk.png" alt="" />

                            </a>
                        </div>
                    </div>
                    <div className='rates'>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.8 Яндекс</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star2.png" alt="" />
                            <span>4.9 Google</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Авито</span>
                        </div>
                        <div className="rate">
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <img src="static/img/star.png" alt="" />
                            <span>5.0 Wdomain</span>
                        </div>
                    </div>
                </div>
                <div className="homeHeaderImage">
                    <img src="static/img/hand.png" alt="" />
                </div>
            </div>
            <p className='headerSocialText'>Мы тут есть</p>
            <div id='headerSocialLinks' className="headerSocialLinks">
                <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">
                    <img src="static/img/icons/insta.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
                    <img src="static/img/icons/tg.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
                    <img src="static/img/icons/waapp.png" alt="" />
                </a>

                <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">
                    <img src="static/img/icons/vk.png" alt="" />

                </a>
            </div>

            <Mailing />
        </>
    )
}