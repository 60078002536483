import React, { useEffect, useRef, useState } from 'react'
import EducationPoint from './educationPoint'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import './botEducation.css'
import Support from '../support/support';



const eduData = [
  {
    "count": 1,
    "time": "120 -180 мин",
    "list": [
      "Что такое голосовой бот на ИИ какой функционал он может выполнять",
      "Знакомство с интерфейсом меню BAKSBO-BOT",
      "Знакомство с конструктором BAKSBO-BOT",
      "Изучаем вспомогательные инструменты для работы",
      "Домашнее задание в формате тестирования",
    ]
  },
  {
    "count": 2,
    "time": "120 -180 мин",
    "list": [
      "Разбор домашнего задания",
      "Структуризация и логика разработки голосового бота",
      "Интеграция с вспомогательными инструментами",
      "Тестирование разработанного голосового бота на ИИ",
      "Открытие доступа к платформе BAKSBO-BOT",
      "Домашнее задание по разработке первого голосового бота",
    ]
  },
  {
    "count": 3,
    "time": "120 -180 мин",
    "list": [
      "Разбор домашнего задания и всех вопросов по разработке бота",
      "Экзамен к допуску платформы BAKSBO-BOT в онлайн режиме",
      "Открытие допуска при положительной сдаче экзамена без ограничений",
      "Допуск к практике если у вас формат обучения «Наставничество»",
    ]
  },
  {
    "count": 4,
    "time": "60 мин",
    "list": [
      "Совместная разработка голосового бота под ваши задачи",
      "Проработка структуризации",
      "Озвучка",
      "Тестирование",
      "Доработка и улучшение алгоритма",
      "Запуск бота для обзвона базы",
      "Анализ работы бота и сбор информации",
    ]
  },
  {
    "count": 5,
    "time": "60 мин",
    "list": [
      "Совместная разработка голосового бота под ваши задачи",
      "Проработка структуризации",
      "Озвучка",
      "Тестирование",
      "Доработка и улучшение алгоритма",
      "Запуск бота для обзвона базы",
      "Анализ работы бота и сбор информации",
    ]
  },
  {
    "count": 6,
    "time": "60 мин",
    "list": [
      "Совместная разработка голосового бота под ваши задачи",
      "Проработка структуризации",
      "Озвучка",
      "Тестирование",
      "Доработка и улучшение алгоритма",
      "Запуск бота для обзвона базы",
      "Анализ работы бота и сбор информации",
    ]
  },
  {
    "count": 7,
    "time": "60 мин",
    "list": [
      "Совместная разработка голосового бота под ваши задачи",
      "Проработка структуризации",
      "Озвучка",
      "Тестирование",
      "Доработка и улучшение алгоритма",
      "Запуск бота для обзвона базы",
      "Анализ работы бота и сбор информации",
    ]
  },
]

const BotEducation = () => {

  const bot_education = useRef(null)

  const [payMetodsItem1, setPayMetodsItem1] = useState(false)
  const [payMetodsItem2, setPayMetodsItem2] = useState(false)
  const [active, setActive] = useState(false)
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)



  const [sliderData, setSliderData] = useState({
    // rewind: true,
    perPage: 1,
    focus: 0,
    omitEnd: true,
    type: 'loop',
    className: 'studnetsReviewsSliderItem',
    autoplay: true,
  })

  useEffect(() => {
    let link = window.location.href.split('/')
    link = link[link.length - 1]    
    if (link === 'bot#bot_education') {
        window.scrollTo({ behavior: 'smooth', top: bot_education.current.offsetTop })
    }
}, [])

  useEffect(() => {
    if (window.screen.width < 1200) {
      setPayMetodsItem1(true)
      setPayMetodsItem2(true)
    }
  }, [])
  return (
    <>
    <br />
      <div className='waveBotHeaderText'>
        <h1 ref={bot_education} id='bot_education' className='botHeaderText'>Обучение по разработке бота</h1>
      </div>
      <div className='botEducationDiv'>
        <div className="botEducation">
          <h1 className='botHeaderText'>Плюсы прохождения обучения</h1>          
          <p>
            <i>Вам не нужно быть разработчиком</i> или знать на отлично устройство современных технологий,
            <i> наша платформа — это конструктор, </i> где вы собираете бота из тех деталей, которые вам предложены,
            с минимальным знанием работы ПК вы уже можете овладеть разработкой голосовых ботов.
          </p>
          <div className='forSomeoneDiv'>
            <svg xmlns="http://www.w3.org/2000/svg" width="173" height="159" viewBox="0 0 173 159" fill="none">
              <g filter="url(#filter0_d_12_539)">
                <path d="M22.5253 37.2072C29.1856 56.9068 41.3892 77.702 61.6565 85.7685C74.6086 90.9235 91.6758 87.1248 100.605 76.3655C109.038 66.2042 113.302 52.3722 105.461 40.7731C101.375 34.7272 95.153 31.2448 87.969 34.2751C76.9936 38.9048 73.0918 55.3192 73.8209 65.9123C76.0961 98.9713 99.0344 133.089 129.546 146.4" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                <path d="M111.552 145.648C115.853 146.286 125.653 147.469 130.448 147.09" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                <path d="M130.449 147.091C127.927 143.531 122.417 135.353 120.55 131.113" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
              </g>
              <defs>
                <filter id="filter0_d_12_539" x="15.6796" y="30.3168" width="121.614" height="127.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_539" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_539" result="shape" />
                </filter>
              </defs>
            </svg>
            <div className={`forSomeone forFreelance ${payMetodsItem1 ? "forSomeoneHide" : ""}`}>
              <h2>
                <img src="/static/img/bot/freelance.png" alt="" />
                Для фрилансера
              </h2>
              <ul>
                <li>изучите для себя уникальный и новый вид дохода из любой точки мира</li>
                <li>сможете помогать бизнесам создавая для них уникальных голосовых ботов</li>
                <li>нет никаких ограничений и комиссий на вашу разработку</li>
                <li>создавая одного голосового бота вы можете зарабатывать до 180 000 руб.</li>
                <li>зарабатывайте не только на разработке, но и на минутах работы бота</li>
              </ul>
              <img className='forSomeoneCoins forSomeoneCoins1' src="/static/img/bot/coins1.png" alt="" />
              <div onClick={() => setPayMetodsItem1(!payMetodsItem1)} className='payMetodsHideDiv'>
                <img src="/static/img/bot/hideImg.png" alt="" />
              </div>
            </div>

            <div className={`forSomeone forBusines ${payMetodsItem2 ? "forSomeoneHide" : ""}`}>
              <h2>
                <img src="/static/img/bot/busines.png" alt="" />
                Для бизнеса
              </h2>
              <ul>
                <li>самостоятельно сможете разрабатывать голосовых ботов на ИИ</li>
                <li>изучите все хитрости разработки и структуризации</li>
                <li>разрабатывайте столько ботов сколько вам нужно без ограничений</li>
                <li>оплачиваете только минуты звонка, бот разрабатывается бесплатно</li>
                <li>ощутимая экономия до 180 000 руб. на разработке одного бота</li>
              </ul>
              <img className='forSomeoneCoins forSomeoneCoins2' src="/static/img/bot/coins2.png" alt="" />
              <img className='forSomeoneCoins forSomeoneCoins3' src="/static/img/bot/coins3.png" alt="" />
              <div className='forSomeoneElipseShadow'>

              </div>
              <div onClick={() => setPayMetodsItem2(!payMetodsItem2)} className='payMetodsHideDiv'>
                <img src="/static/img/bot/hideImg.png" alt="" />
              </div>
            </div>
          </div>
          <h1 className='botHeaderText'>Индивидуальное обучение в онлайн формате</h1>
          <p>
            <i>Дату и время прохождения обучения вы можете обсудить лично с куратором,</i> мы сделаем так,
            чтобы вам было максимально комфортно проходить обучение и не мешало вашему повседневному распорядку дня.
          </p>
        </div>

        <div className="botEducationProgresScroll">
          <div className="botEducationProgres">
            <img src="/static/img/bot/vector.png" alt="" className="botEducationProgresVector" />
            {eduData.map(item =>
              <EducationPoint data={item} />
            )

            }
            <div className='forSomeoneElipseShadow'></div>
          </div>
        </div>
        <span className='botEducationIdk'>*При случае отрицательной сдачи экзамена, вы не допускаетесь к работе с платформой. Пересдача экзамена производиться через 5-7 дней, чтобы вы могли лучше подготовиться и повторить информацию по разработке.</span>
        <div className='studnetsReviewsDiv'>
          <div className='studnetsReviewsInfo'>
            <h1 className='botHeaderText'>Отзывы учеников</h1>
            <p>Наши ученики делятся своими впечатлениями о обучении они успешно справляются с вызовами и достигают своих учебных целей. Вот что они говорят.</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="173" height="159" viewBox="0 0 173 159" fill="none">
              <g filter="url(#filter0_d_12_539)">
                <path d="M22.5253 37.2072C29.1856 56.9068 41.3892 77.702 61.6565 85.7685C74.6086 90.9235 91.6758 87.1248 100.605 76.3655C109.038 66.2042 113.302 52.3722 105.461 40.7731C101.375 34.7272 95.153 31.2448 87.969 34.2751C76.9936 38.9048 73.0918 55.3192 73.8209 65.9123C76.0961 98.9713 99.0344 133.089 129.546 146.4" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                <path d="M111.552 145.648C115.853 146.286 125.653 147.469 130.448 147.09" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                <path d="M130.449 147.091C127.927 143.531 122.417 135.353 120.55 131.113" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
              </g>
              <defs>
                <filter id="filter0_d_12_539" x="15.6796" y="30.3168" width="121.614" height="127.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_539" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_539" result="shape" />
                </filter>
              </defs>
            </svg>
            <div>
              <img src="/static/img/bot/screen1.png" alt="" />
              <img src="/static/img/bot/screen3.png" alt="" />
              <img src="/static/img/bot/screen2.png" alt="" />
            </div>
          </div>
          <div className='studnetsReviewsSlider'>
            <Splide
              options={sliderData}
            >

              <SplideSlide>
                <img src="/static/img/bot/slide1.png" alt="" />
              </SplideSlide>
              <SplideSlide>
                <img src="/static/img/bot/slide2.png" alt="" />
              </SplideSlide>
              <SplideSlide>
                <img src="/static/img/bot/slide3.png" alt="" />
              </SplideSlide>
              <SplideSlide>
                <img src="/static/img/bot/slide4.png" alt="" />
              </SplideSlide>
            </Splide>
          </div>
        </div>
        <div className='selectEduFormatInfo'>
          <h1 className='botHeaderText'>Выберите свой формат обучения</h1>
          <h4><i>ВНИМАНИЕ!</i> К обучению допускаются <i>не все желающие!</i> </h4>
          <p>
            Прежде чем вы начнете обучение, необходимо пройти собеседование с ментором, где он задаст необходимые вопросы и выявит потребности, после вы узнаете о допуске к прохождению.
          </p>
        </div>
        <div className='selectEduFormat'>
          <div className='selectEduFormatBlock selectEduFormatBlock1'>
            <h2>
              <img src="/static/img/bot/question.png" alt="" />
              Вопросы
            </h2>
            <h1>4900 руб</h1>
            <hr />
            <ul>
              <li>1 час консультации</li>
            </ul>

            <div className='selectEduFormatBlockButtonDiv'>
              <a onClick={() => setActive(true)}>
                <button>Забронировать</button>
              </a>
            </div>

          </div>
          <div className='selectEduFormatBlock selectEduFormatBlock2'>
            <h2>
              <img src="/static/img/bot/rocket_launch.png" alt="" />
              Запуск
            </h2>
            <h4>
              длительность
              <h5>3 недели</h5>
            </h4>
            <h1>69000 руб</h1>
            <hr />
            <ul>
              <li>3 дня теории (одна сессия в неделю по 120 - 180 минут)</li>
              <li>1 час консультации</li>
              <li>доступ к платформе без ограничений</li>
              <li>запись обучения первого и второго дня</li>
            </ul>
            <div className='selectEduFormatBlockButtonDiv'>

              <a onClick={() => setActive1(true)}>
                <button>Пройти собеседование</button>
              </a>
            </div>

          </div>
          <div className='selectEduFormatBlock selectEduFormatBlock3'>
            <h2>
              <img src="/static/img/bot/crop_free.png" alt="" />
              Наставничество
            </h2>
            <h4>
              длительность
              <h5>5 недель</h5>
            </h4>
            <h1>99000 руб</h1>
            <hr />
            <ul>
              <li>3 дня теории (одна сессия в неделю по 120 - 180 минут)</li>
              <li>4 дня практики, совместная разработка (две сессии в неделю по 60 минут)</li>
              <li>2 час консультации</li>
              <li>доступ к платформе без ограничений</li>
              <li>запись обучения первого и второго дня</li>
            </ul>
            <div className='selectEduFormatBlockButtonDiv'>
              <a onClick={() => setActive2(true)}>
                <button>Пройти собеседование</button>
              </a>
            </div>
          </div>
        </div>
        <span className='botEducationIdk'>*Есть возможность пройти ускоренное обучение + практику, условия обговариваются индивидуально, подробности можете уточнить у нашего менеджера в WhatsApp или Telegram.</span>
        <div className='botEducationCredidDiv'>
          <img className='botEducationCredidImg1' src="/static/img/bot/treeLines.png" alt="" />
          <img className='botEducationCredidImg2' src="/static/img/bot/treeLines.png" alt="" />
          <img className='botEducationCredidImg3' src="/static/img/bot/hor_lines.png" alt="" />
          <p>
            Оплачивайте любым для вас удобным способом <br />
            60/40 или в рассрочку
          </p>
        </div>
      </div>
      <Support active={active} category='Вопросы' setFunc={setActive}/>
      <Support active={active1} category='Запуск' setFunc={setActive1}/>
      <Support active={active2} category='Наставничество' setFunc={setActive2}/>
    </>
  )
}

export default BotEducation