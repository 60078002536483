import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import BotForm from '../botKace/botForm'
import './botPrice.css'

const BotPrice = () => {

    const [active, setActive] = useState(false)
    const [formTheme, setFormTheme] = useState('')

    // const priceList = [
    //     { 'title': 'BAKSBO-BOT', 'price': '38 900', 'lid': '130', 'devTime': '2-4', "audit": '5000', "run": '1000' },
    //     { 'title': 'BAKSBO-BOT 1.0', 'price': '55 900', 'lid': '90', 'devTime': '4-6', "audit": '8000', "run": '3000' },
    //     { 'title': 'BAKSBO-BOT 2.0', 'price': '83 900', 'lid': '70', 'devTime': '6-8', "audit": '10000', "run": '5000' },
    // ]
    return (
        <>
            <div className="botTarifsDiv newBotPriceDiv" id='bot_tarifs'>
                <h1 className="botHeaderText">
                    Разработка BAKSBO-BOT
                </h1>
                <div className='botTarif'>
                    <h1>от 2500 руб.</h1>
                    <svg xmlns="http://www.w3.org/2000/svg" width="173" height="159" viewBox="0 0 173 159" fill="none">
                        <g filter="url(#filter0_d_12_539)">
                            <path d="M22.5253 37.2072C29.1856 56.9068 41.3892 77.702 61.6565 85.7685C74.6086 90.9235 91.6758 87.1248 100.605 76.3655C109.038 66.2042 113.302 52.3722 105.461 40.7731C101.375 34.7272 95.153 31.2448 87.969 34.2751C76.9936 38.9048 73.0918 55.3192 73.8209 65.9123C76.0961 98.9713 99.0344 133.089 129.546 146.4" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                            <path d="M111.552 145.648C115.853 146.286 125.653 147.469 130.448 147.09" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                            <path d="M130.449 147.091C127.927 143.531 122.417 135.353 120.55 131.113" stroke="#F7D81A" stroke-width="5.69" stroke-linecap="round" />
                        </g>
                        <defs>
                            <filter id="filter0_d_12_539" x="15.6796" y="30.3168" width="121.614" height="127.69" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_539" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_539" result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    <p>под ключ</p>
                    <a
                        onClick={
                            () => {
                                const funcion1 = setActive(true)
                                const funcion2 = setFormTheme('BAKSBO-BOT')
                            }}                   >
                        Увеличить свою прибыль
                        {/* <img src="/static/img/bot/arrowLine.png" alt="" /> */}
                    </a>

                </div>
                <img className='phoneeithwifi' src="/static/img/bot/botik.png" alt="" />
                <div className="botTarifElipse"><img src="/static/img/bot/coins.png" alt="" /></div>
                <div className="botTarifElipse botTarifElipse_2"><img src="/static/img/bot/coin.png" alt="" /></div>
                <img className='vectorLine' src="/static/img/bot/vectorLine.png" alt="" />
            </div>
           <BotForm active={active} setActive={setActive} theme={formTheme} />

        </>
        // <>
        //     <h1 className='botHeaderText'>
        //         <i>Стоимость голосовых ботов</i> под <br /> ключ для любой сферы бизнеса
        //     </h1>
        //     <div className='botPriceDiv'>
        //         <img className='botPriceFon1' src="/static/img/bot/back2.png" alt="" />
        //         <img className='botPriceFon2' src="/static/img/bot/back3.png" alt="" />
        //         <div className="botPriceItem">
        //                     <div className="botPrice">
        //                         <div className='botPriceInfo'>
        //                             <h1>BAKSBO-INFO</h1>
        //                             <h2>от 9 900 руб</h2>

        //                             <span>Бот любой сложности</span>
        //                             <span>Время разработки:&nbsp;<i> 1-2 </i>&nbsp;дня</span>

        //                             <span className='mobileSpans'>Время:&nbsp;<i> 1-2 </i>&nbsp;дня</span>
        //                         </div>
        //                         <div className='botPriceInfo botPriceBonus'>
        //                             <p>Бонус</p>
        //                             <span>Бесплатная консультация по запуску BAKSBO-INFO</span>
        //                             <span>Поддержка бота: <i> &nbsp;7&nbsp;</i> дней</span>
        //                             <span className='mobileSpans'>Бесплатная консультация по запуску BAKSBO-INFO</span>
        //                             <span className='mobileSpans'>Поддержка бота: <br /> 7 дней</span>
        //                         </div>
        //                     </div>
        //                     <div className='botPriceButtonDiv'>
        //                         <span onClick={                                   
        //                             () => {
        //                                 const funcion1 = setActive(true)
        //                                 const funcion2 = setFormTheme('BAKSBO-INFO')
        //                             }
        //                         }>Заказать</span>
        //                     </div>
        //                 </div>
        //         {priceList.map(item => {
        //             return (
        //                 <div className="botPriceItem">
        //                     <div className="botPrice">
        //                         <div className='botPriceInfo'>
        //                             <h1>{item['title']}</h1>
        //                             <h2>{item['price']} руб</h2>
        //                             {item['lid'] &&
        //                             <span>Стоимость заявки:&nbsp;<i> {item['lid']} руб</i></span>}
        //                             <span>Время разработки:&nbsp;<i> {item['devTime']} </i>&nbsp;дня</span>
        //                             <span className='mobileSpans'>Лид:&nbsp;<i> {item['lid']} руб</i></span>
        //                             <span className='mobileSpans'>Время:&nbsp;<i> {item['devTime']} </i>&nbsp;дня</span>
        //                         </div>
        //                         <div className='botPriceInfo botPriceBonus'>
        //                             <p>Бонус</p>
        //                             <span>Возможная целевая аудитория: {item['audit']} номеров</span>
        //                             <span>На запуск бота: {item['run']} руб</span>
        //                             <span className='mobileSpans'>Воз. целевая аудитория: <br /> {item['audit']} номеров</span>
        //                             <span className='mobileSpans'>На запуск бота: <br /> {item['run']} руб</span>
        //                         </div>
        //                     </div>
        //                     <div className='botPriceButtonDiv'>
        //                         <span onClick={                                   
        //                             () => {
        //                                 const funcion1 = setActive(true)
        //                                 const funcion2 = setFormTheme(item['title'])
        //                             }
        //                         }>Заказать</span>
        //                     </div>
        //                 </div>
        //             )
        //         })}
        //     </div>
        //     <BotForm active={active} setActive={setActive} theme={formTheme} />

        // </>
    )
}

export default BotPrice