import React, { useState } from 'react'


const BotHeader = () => {
  return (
    <>
      <div className='botHeder homeHeader'>
        <div className="botHederFon">
          <img src="/static/img/bot/back1.5.png" alt="" />
        </div>
        <div className="homeHeaderText">
          <h2><i>Голосовой бот на искуственом интелекте</i> под любые задачи!
          <h3>Разработка под ключ / Обучение по разработке</h3>
          </h2>
          <p>Начните <i>зарабатывать больше с помощью голосового бота,</i> который приведет вам больше клиентов в бизнес, а для фрилансеров возможность разрабатывать бота для бизнеса и зарабатывать на этом!</p>
          <p>Почему нужно выбирать именно нас?</p>
          <ul>
            <li>Опытная команда разработчиков и маркетологов</li>
            <li>Индивидуальное обучение по разработке</li>
            <li>Озвучка профессиональными дикторами</li>
            <li>Посекундная тарификация = 3,8 руб минута звонка</li>
            <li>Собственная телефония без доп. оплат</li>
            <li>Управление через личный кабинет с любого устройства</li>
          </ul>
          <div className='homeHeaderButtonDiv'>
            <a href="#bot_tarifs">
              <button className='getPresent'>Голосовой бот под ключ</button>
            </a>
            <a href="#bot_education">
              <button className='getPresent'>Обучение по разработке бота</button>
            </a>
          </div>
        </div>
        <div className="homeHeaderImage">
          <img src="/static/img/bot/bot_header_image.png" alt="" />
        </div>
      </div>      
    </>
  )
}

export default BotHeader