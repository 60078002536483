import './style.css'
import MessageMailing from '../messageMailing/messageMailing'
import SelfMailing from '../selfMailing/selfMailing'
import WeWork from '../weWork/weWork'
import Bounus from '../bonus/bonus'
import HomeForm from '../homeForm/homeForm'
import Case from '../case/case'
import Benefice from '../benefice/benefice'
import StatisticCase from '../statisticKase/statisticCase'
import BotCommercial from '../botCommercial/botCommercial'

export default function Mailing() {

    return (
        <>
            <div className="mailingDiv">
                <div id='mailings' className="messageMailing">
                    <h1>рассылка сообщений</h1>
                    <p>Мы предоставляем рассылку сообщений по всему миру без каких-либо ограничений. <i>Возможность собирать клиентские базы для рассылки</i> также по всему миру.</p>
                </div>
                <MessageMailing/>
                <SelfMailing />
                <BotCommercial/>
                <StatisticCase/>
                <Case />
                <Benefice/>
                <WeWork />
            </div>
            <div className="legs">
                <div style={{ marginTop: '100px' }}></div>
                <Bounus />
                <HomeForm />
                <div className='garantDiv'>
                    <div>
                        <h1>Гарантия выполнения</h1>
                        <div className="workLine"></div>
                        <p>НАМ ВАЖНО, ЧТОБЫ КЛИЕНТ ВОЗВРАЩАЛСЯ!</p>
                        <span>Во время выполнения заказа мы <i>предоставляем фотоотчёт и дополнительные материалы
                            по исполнению работы</i> и на протяжении всей рассылки поддерживаем связь с клиентом. Так
                            вы можете полностью довериться нам, потому что мы ничего не скрываем!</span>
                    </div>
                    <div>
                        <h1>Заключение договора</h1>
                        <div className="workLine"></div>
                        <p>ЗАКЛЮЧАЕМ ДОГОВОР ДЛЯ НАДЕЖНОСТИ</p>
                        <span><i>Мы ценим и бережём отношения с каждым клиентом,</i> поэтому заключаем двусторонний договор
                            на оказание услуг по рассылке сообщений. Какие данные требуются от лица заказчика и от
                            какого количества сообщений заключается договор узнайте у менеджера, для этого оставьте
                            заявку на нашем сайте.</span>
                    </div>
                </div>

                <div className="weAreTrust">
                    <p>нам доверяют</p>
                    <div className='trustDiv'>
                        <div className='photoTrust'>
                            <img src="static/img/partner1.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner2.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner3.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner4.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner5.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner6.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner7.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner8.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner12.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner9.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner10.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner11.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner13.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner14.png" alt="" />
                        </div>
                        <div className='photoTrust'>
                            <img src="static/img/partner15.png" alt="" />
                        </div>
                    </div>
                </div>
                <h2 className='faqHeader'>Часто задаваемые вопросы</h2>
                <div className='homeFAQ'>
                    <ul>
                        <li>Какую рассылку сообщений лучше выбрать?</li>
                        <br />
                        <p>- Если вы ещё не решили, какая рассылка сообщений в Instagram, Telegram, WhatsApp, Viber, ВКонтакте вам больше всего подходит для привлечения новых клиентов, то мы вам с этим обязательно поможем! Каждая рассылка по-своему уникальный продукт и имеет свои нюансы в работе, необходимо обсудить детали вашего бизнеса, так как в первую очередь нужно отталкиваться от структуры самого направления и ваших пожеланий. Чтобы определить, какая рассылка сообщений в мессенджер или соцсеть подходит именно вашему бизнесу, рекомендуем заполнить любую из форм на нашем сайте и мы свяжемся с вами для бесплатной консультации.</p>
                    </ul>
                    <ul>
                        <li>Какие гарантии рассылки?</li>
                        <br />
                        <p>- Мы за долгосрочное сотрудничество с каждым клиентом и прорабатываем каждый заказ максимально детально, независимо от объёма отправленных сообщений и вида рассылки. В виде гарантии отправки сообщений мы можем предоставить: фото, видео, базу, по которой делается рассылка и excel отчёт, тем самым вы можете убедиться в правдоподобности рассылки. Также на сайте заключается оферта, с ней вы можете ознакомиться ниже. В ней расписаны все обязательства с нашей стороны, которые мы должны выполнить в полном объёме перед заказчиком. Наши менеджеры всегда на связи на протяжении всей работы, вы сразу поймёте, что нам нечего скрывать!</p>
                    </ul>
                    <ul>
                        <li>Поможете ли написать текст для рассылки?</li>
                        <br />
                        <p>- Каждому клиенту в виде БОНУСА наш модератор обязательно помогает с написанием текста для массовой рассылки сообщений. После того, как ваш заказ будет подтверждён, наш менеджер вышлет вам шпаргалку для написания основы текста. Почему нам нужна основа именно от вас? Так как свой бизнес вы знаете намного лучше чем мы, возможно у вас есть уникальность, которая вас выделяет среди конкурентов, приложите оффер и ссылки на те ресурсы, куда будем вести аудиторию из рассылки. Данную основу текста вам необходимо написать не сильно вникая в структуру сообщения. Большую часть работы по тексту выполнит наш модератор, который структуризирует текст для рассылки и максимально повысит кликабельность, тем самым увеличив вовлечённость клиента и поднимет конверсию рассылки.</p>
                    </ul>
                    <ul>
                        <li>Где взять базу для рассылки?</li>
                        <br />
                        <p>- Если у вас нет своей базы для рассылки, то мы поможем вам с этим. Наша команда аналитиков проанализирует вашу нишу и подберёт ресурсы для сбора базы, после этого вам высылают их на согласование, чтобы вы могли лично посмотреть, от куда мы планируем собирать базу. Если у вас уже есть пожелания, от куда вы хотите собрать базу, то высылайте ссылки на ресурсы и мы приступим к сборке. Также при сборке база фильтруется для отсеивания ненужных нам для рассылки пользователей. Виды фильтрации в каждом виде рассылки имеет свои настройки, если вам необходимо узнать, какие настройки в фильтре мы можем сделать в той или иной рассылке, то свяжитесь с нашим менеджером или оставьте заявку на бесплатную консультацию и мы вам обо всем подробно расскажем.</p>
                    </ul>
                    <ul>
                        <li>Что такое рассылка сообщений под ключ?</li>
                        <br />
                        <p>- Мы-команда профессионалов с большим опытом работы в сфере лидогенирации через рассылки четко понимаем, что необходимо для рассылки в той нише, где находится ваш бизнес. Поэтому мы решили все виды рассылки сообщений, которые представлены на нашем сайте, реализовывать под ключ. Тем самым мы закрываем все этапы подготовки за нами, вам остаётся только согласовывать те или иные этапы подготовки. Таким образом мы открыто показываем всю структуру рассылки и ничего не скрываем от вас.</p>
                    </ul>
                    <ul>
                        <li>Сколько стоит рассылка сообщений?</li>
                        <br />
                        <p>- Стоимость рассылки сообщений зависит от двух факторов: стоимость одного сообщения и стоимость базы. База в большинстве случаев предоставляется бесплатно. У каждого вида рассылки есть свои минимальные лимиты для заказа. Все тарифы указаны индивидуально на каждой странице рассылки Instagram, Telegram, WhatsApp, Viber и ВКонтакте. В блоке «тарифы» подробно описана стоимость и ее подсчёт. Если у вас возникли вопросы о стоимости рассылки, то пожалуйста заполните форму «бесплатная консультация» и мы свяжемся с вами .</p>
                    </ul>
                    <ul>
                        <li>Сколько стоит рассылка сообщений?</li>
                        <br />
                        <p>- Стоимость рассылки сообщений зависит от двух факторов которые влияют на итоговую стоимость: </p>
                        <p>&nbsp;&nbsp;• Стоимость одного сообщения</p>
                        <p>&nbsp;&nbsp;• Стоимость сбора базы </p>
                        <p>База в большинстве случаев предоставляется бесплатно. У каждого вида рассылки есть свои минимальные лимиты для заказа. Все тарифы указаны индивидуально на каждой странице рассылки Instagram, Telegram, WhatsApp, Viber и ВКонтакте. В блоке «тарифы» подробно описана стоимость и ее подсчёт. Если у вас возникли вопросы о стоимости рассылки, то заполните форму «бесплатная консультация» и мы свяжемся с вами.</p>
                    </ul>
                </div>
            </div>
        </>
    )
}