import React, { useEffect, useRef, useState } from 'react'
import "./payMetods.css"
import Support from '../support/support'

const PayMetods = () => {

    const [active, setActive] = useState(false)
    const [active1, setActive1] = useState(false)
    const [active2, setActive2] = useState(false)
    const [payMetodsItem1, setPayMetodsItem1] = useState(false)
    const [payMetodsItem2, setPayMetodsItem2] = useState(false)
    const [payMetodsItem3, setPayMetodsItem3] = useState(false)

    useEffect(() => {
        if (window.screen.width < 1200) {
            setPayMetodsItem1(true)            
            setPayMetodsItem3(true)
        }
    }, [])


    return (
        <div className='payMetodsDiv'>
            <h1 className='botHeaderText'>Способы оплаты</h1>
            <div className='payMetods'>
                <div className={`payMetodsItem ${payMetodsItem1 ? "payMetodsItemHide" : ''}`}>
                    <h1>Рассрочка: <i>Тинькофф банк</i></h1>
                    <h2>0% <h3>/ от 3х до 24х месяцев</h3></h2>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Без переплат</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Решение в течение 30 мин.</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Оплата обучения</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Оплата голосового бота</p>
                    <span>Есть уникальная возможность разработать голосового бота или пройти обучение в рассрочку без переплат и первоначального взноса от наших партнеров.</span>
                    <img className='payMetodsLogo' src="/static/img/bot/tinkoff.png" alt="" />
                    <hr />
                    <a onClick={() => setActive(true)}>
                        <button>Подробнее</button>
                    </a>
                    <div onClick={() => setPayMetodsItem1(!payMetodsItem1)} className='payMetodsHideDiv'>
                        <img src="/static/img/bot/hideImg.png" alt="" />
                    </div>
                </div>
                <div className={`payMetodsItem payMetodsItemMid ${payMetodsItem2 ? "payMetodsItemHide" : ''}`} >
                    <h1>Оплата в два этапа</h1>
                    <h2>60/40%</h2>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Оплата по расчетному счету</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Оплата по эквайрингу</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Заключение договора</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Бесплатная консультация</p>
                    <span>Разработку голосового бота и обучение можно оплатить в два этапа, это позволяет снизить вашу финансовую нагрузку и быть уверенным в выполнение нами работы.</span>
                    <hr />
                    <a onClick={() => setActive1(true)}>
                        <button>Подробнее</button>
                    </a>
                    {/* <div onClick={() => setPayMetodsItem2(!payMetodsItem2)} className='payMetodsHideDiv'>
                        <img src="/static/img/bot/hideImg.png" alt="" />
                    </div> */}
                </div>
                <div className={`payMetodsItem ${payMetodsItem3 ? "payMetodsItemHide" : ''}`}>
                    <h1>Рассрочка: <i>МТС банк</i></h1>
                    <h2>0% <h3>/ от 3х до 24х месяцев</h3></h2>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Без переплат</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Решение в течение 30 мин.</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Оплата обучения</p>
                    <p>
                        <img src="/static/img/bot/pseudo.png" alt="" />
                        Оплата голосового бота</p>
                    <span>Есть уникальная возможность разработать голосового бота или пройти обучение в рассрочку без переплат и первоначального взноса от наших партнеров.</span>
                    <img className='payMetodsLogo' src="/static/img/bot/mts.png" alt="" />
                    <hr />
                    <a onClick={() => setActive2(true)}>
                        <button>Подробнее</button>
                    </a>
                    <div onClick={() => setPayMetodsItem3(!payMetodsItem3)} className='payMetodsHideDiv'>
                        <img src="/static/img/bot/hideImg.png" alt="" />
                    </div>
                </div>
            </div>
            <Support active={active} category='Рассрочка: Тинькофф банк' setFunc={setActive}/>
            <Support active={active1} category='Оплата в два этапа' setFunc={setActive1}/>
            <Support active={active2} category='Рассрочка: МТС банк' setFunc={setActive2}/>
        </div >
    )
}

export default PayMetods