import './style.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

export default function WeWork() {
    useEffect(() => {
        Aos.init({ duration: 1000 })

    }, [])

    return (
        <>
            <h1 className='howWeWork'>Как мы работаем?</h1>
            <div className='workSection'>
                <div  className="workImage"><img  data-aos="fade-up"  src="static/img/humans.png" alt="" /></div>
                <div className="workText">
                    <h1>Сделаем всю работу за вас</h1>
                    <div className='workLine'></div>
                    <p><i>Наша команда подготовит полностью все этапы  для запуска любой рассылки. Этапы подготовки:</i></p>
                    <span>  - аналитика вашей ниши;<br />
                        - сбор целевой базы;<br />
                        - фильтрация целевой базы;<br />
                        - определение структуризации сообщений;<br />
                        - помощь в написании текста для рассылки;<br />
                        - запуск рассылки (с предоставлением отчетов);<br />
                        - поддержка клиентского отдела на протяжении всей работы..  <br />
                    </span>
                </div>
            </div>
            <div className='workSection'>
                <div className="workText">
                    <h1>Соберём базу для рассылки</h1>
                    <div className='workLine'></div>
                    <p><i>МНОЖЕСТВО ПОРАМЕТРОВ ДЛЯ СБОРА БАЗЫ</i></p>
                    <span>Рассылку можем производить <i>по вашим готовым клиентским базам или собрать под вас базу контактов с нуля по обговорённым параметрам,</i> которые возможно применить в нашей программе, это позволит вам максимально эффективно произвести рассылку.</span>
                </div>
                <div className="workImage" ><img  data-aos="fade-up"  src="static/img/excel.png" alt="" /></div>

            </div>
            <div className='workSection'>
                <div className="workImage" ><img  data-aos="fade-up"  src="static/img/spaceman.png" alt="" /></div>
                <div className="workText">
                    <h1>Новые клиенты в ваш бизнес</h1>
                    <div className='workLine'></div>
                    <p><i>ПОЛУЧАЙТЕ КЛИЕНТОВ СДЕСЬ И СЕЙЧАС</i></p>
                    <span>Находите быстрее своих клиентов с помощью массовой рассылки в instagram, WhatsApp, Telegram, Viber, ВКонтакте. <i> Клиент получает ваше составленное сообщение с уведомлением,</i> тем самым не может его пропустить и обязательно прочитает, так как сообщение будет отображаться как непрочитанное.</span>
                </div>
            </div>
        </>
    )
}