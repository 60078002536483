import React from 'react'
import './botTraining.css'
import BotEducation from '../../../../elements/botEducation/botEducation'

const BotTraining = () => {
    return (
        // <div className='botTrainingDiv trainingDiv'>
        //     <div className="selfMailingItem telegramItem">
        //         <div className='selfMailingInfo'>
        //             <div className="selfMailingInfoText">
        //                 <p className='selfMailingInfoHeader'>baksbo-bot</p>
        //             </div>
        //             <div className='selfMailingInfoButtons'>
        //                 <div className='selfMailingInfoTraining'>
        //                     <p>
        //                         Пройдите бесплатное обучение прямо сейчас и узнайте больше о том, как правильно настраивать BAKSBO-BOT.
        //                         <br />
        //                         Если у вас остались вопросы, то вы всегда можете задать их нашему менеджеру.


        //                     </p>
        //                 </div>
        //                 <div className='selfMailingInfoButtonDiv'>
        //                     <a target={'_blank'} href={'https://youtu.be/OvC_6jT42TE'}>
        //                         <section className='selfMailingInfoTraningButton'>
        //                             <div>
        //                                 <img src="/static/img/bot/excel_mask.png" alt="" />
        //                                 <p>Формирование<br /> контактов</p>
        //                             </div>
        //                             <a target={'_blank'} href={'https://youtu.be/OvC_6jT42TE'}>Обучение</a>
        //                         </section>
        //                     </a>
        //                     <a target={'_blank'} href={'https://youtu.be/_Rcjk8VxbRU'}>
        //                         <section className='selfMailingInfoTraningButton'>
        //                             <div>
        //                                 <img src="/static/img/bot/contact_mask.png" alt="" />
        //                                 <p>Загрузка<br /> контактов</p>
        //                             </div>
        //                             <a target={'_blank'} href={'https://youtu.be/_Rcjk8VxbRU'}>Обучение</a>
        //                         </section>
        //                     </a>
        //                     <a target={'_blank'} href={'https://youtu.be/3GAMtIR-uxo'}>
        //                         <section className='selfMailingInfoTraningButton'>
        //                             <div>
        //                                 <img src="/static/img/bot/play_mask.png" alt="" />
        //                                 <p>Запуск<br /> бота</p>
        //                             </div>
        //                             <a target={'_blank'} href={'https://youtu.be/3GAMtIR-uxo'}>Обучение</a>
        //                         </section>
        //                     </a>

        //                     {/* <section className='selfMailingInfoBuySession'>
        //                         <p>Где можно покупать аккаунты</p>
        //                         <a href='https://hstock.org' target="_blank">https://hstock.org</a>
        //                         <a href='https://darkstore.su' target="_blank">https://darkstore.su</a>
        //                         <a href='https://tg-akk.ru' target="_blank">https://tg-akk.ru</a>
        //                     </section> */}

        //                 </div>
        //             </div>
        //         </div>
        //         <div className='selfMailingItemCover'></div>
        //     </div>
        // <img className='botTrainingFon' src="/static/img/bot/back1.png" alt="" />
        // </div>
        <div className='botEducationCabinet'>
            <div className='waveBotHeaderText'>
                <h1 id='bot_education' className='botHeaderText'>Обучение по разработке бота</h1>
            </div>
            <p className='botEducationCabinetTetx'>
                Узнать подробнее на сайте.
                <a className='botEducationCabinetHref' target='_blank' href="/bot#bot_education">Подробнее</a>
            </p>
        </div>
    )
}

export default BotTraining