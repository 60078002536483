import { useEffect, useRef, useState, useContext } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../App'
import VideoWidget from '../elements/widget/viodeoWidget'
import ButtonWidget from '../elements/widget/buttonWidget'
import CookieWidget from "../elements/cookieWidget/cookieWidget";
import ReferralWidget from "../elements/referralWidget/referralWidget";


export default function Header() {
    const user = useContext(UserContext)
    const navigate = useNavigate();
    const location = useLocation()

    const [homeButtonClass, setHomeButtonClass] = useState('')
    const [selfMailingButtonClass, setSelfMailingButtonClass] = useState('')
    const [mailingButtonClass, setMailingButtonClass] = useState('')
    const [reviewButtonClass, setReviewButtonClass] = useState('')
    const [botButtonClass, setBotButtonClass] = useState('')

    const manuIcon = useRef('')
    const phoneManu = useRef('')
    const phoneFon = useRef('')
    const loader = useRef('')
    const loader1 = useRef('')
    const loader2 = useRef('')
    const loaderTetx = useRef('')
    const loaderImage = useRef('')
    const loaderLogo = useRef('')
    const loaderSpan = useRef('')
    const phoneMailingManu = useRef('')
    const phoneMailingLink1 = useRef('')
    const phoneMailingLink2 = useRef('')
    const phoneMailingLink3 = useRef('')
    const phoneMailingLink4 = useRef('')
    const phoneMailingLink5 = useRef('')
    const manu = useRef('')
    const mailingManu = useRef('')
    const instagramRef = useRef('')
    const telegramRef = useRef('')
    const whatsappRef = useRef('')
    const viberRef = useRef('')
    const vkRef = useRef('')

    const homeButton = useRef('')
    const selfMailingButton = useRef('')
    const mailingButton = useRef('')
    const botButton = useRef('')
    const reviewButton = useRef('')


    function menu(name, link = '/') {
        homeButton.current.classList.remove('bottomBorder')
        selfMailingButton.current.classList.remove('bottomBorder')
        mailingButton.current.classList.remove('bottomBorder')
        botButton.current.classList.remove('bottomBorder')
        reviewButton.current.classList.remove('bottomBorder')
        if (name === 'homeButton') {
            homeButton.current.classList.add('bottomBorder')
        }
        else if (name === 'selfMailingButton') {
            selfMailingButton.current.classList.add('bottomBorder')
        }
        else if (name === 'botButton') {
            botButton.current.classList.add('bottomBorder')
        }
        else if (name === 'mailingButton') {
            mailingButton.current.classList.add('bottomBorder')
        }
        else {
            reviewButton.current.classList.add('bottomBorder')
        }
        navigate(link)
    }
    function mailingManuToggle() {
        if (mailingManu.current.classList.value.includes('mailingManuActive')) {
            setTimeout(() => {
                vkRef.current.style.opacity = 0
                vkRef.current.style.marginLeft = '70px'
            }, 10);
            setTimeout(() => {
                viberRef.current.style.opacity = 0
                viberRef.current.style.marginLeft = '70px'
            }, 100);
            setTimeout(() => {
                whatsappRef.current.style.opacity = 0
                whatsappRef.current.style.marginLeft = '70px'
            }, 150);
            setTimeout(() => {
                telegramRef.current.style.opacity = 0
                telegramRef.current.style.marginLeft = '70px'
            }, 200);
            setTimeout(() => {
                instagramRef.current.style.opacity = 0
                instagramRef.current.style.marginLeft = '70px'
            }, 250);
            setTimeout(() => {
                mailingManu.current.classList.remove('mailingManuActive')
            }, 350);
            setTimeout(() => {
                mailingManu.current.style.display = 'none'
                vkRef.current.style.marginLeft = '-70px'
                viberRef.current.style.marginLeft = '-70px'
                whatsappRef.current.style.marginLeft = '-70px'
                telegramRef.current.style.marginLeft = '-70px'
                instagramRef.current.style.marginLeft = '-70px'
            }, 515);
            return
        }
        mailingManu.current.style.display = 'flex'
        setTimeout(() => {
            mailingManu.current.classList.add('mailingManuActive')
        }, 10);
        setTimeout(() => {
            instagramRef.current.style.opacity = 1
            instagramRef.current.style.marginLeft = 0
        }, 100);
        setTimeout(() => {
            telegramRef.current.style.opacity = 1
            telegramRef.current.style.marginLeft = 0
        }, 150);
        setTimeout(() => {
            whatsappRef.current.style.opacity = 1
            whatsappRef.current.style.marginLeft = 0
        }, 200);
        setTimeout(() => {
            viberRef.current.style.opacity = 1
            viberRef.current.style.marginLeft = 0
        }, 250);
        setTimeout(() => {
            vkRef.current.style.opacity = 1
            vkRef.current.style.marginLeft = 0
        }, 300);

    }

    function scrollTop(open) {
        window.scrollTo(0, 0);
        if (open === "open") {
            openManu()
        }
    }
    let openMobile = 'close'
    function mailingManuMobile() {
        if (openMobile == 'close') {
            openMailing()
            openMobile = 'open'
        } else {
            closeMailing()
            openMobile = 'close'

        }
    }
    function openMailing() {
        phoneMailingManu.current.style.display = 'flex'
        setTimeout(function () {
            phoneMailingManu.current.style.height = '158px'
        }, 100);
        setTimeout(function () {
            phoneMailingLink1.current.style.opacity = '1'
        }, 300);
        setTimeout(function () {
            phoneMailingLink2.current.style.opacity = '1'
        }, 400);
        setTimeout(function () {
            phoneMailingLink3.current.style.opacity = '1'
        }, 500);
        setTimeout(function () {
            phoneMailingLink4.current.style.opacity = '1'
        }, 600);
        setTimeout(function () {
            phoneMailingLink5.current.style.opacity = '1'
        }, 700);
    }
    function closeMailing() {
        setTimeout(function () {
            phoneMailingLink5.current.style.opacity = '0'
        }, 1);
        setTimeout(function () {
            phoneMailingLink4.current.style.opacity = '0'
        }, 100);
        setTimeout(function () {
            phoneMailingLink3.current.style.opacity = '0'
        }, 200);
        setTimeout(function () {
            phoneMailingLink2.current.style.opacity = '0'
        }, 300);
        setTimeout(function () {
            phoneMailingLink1.current.style.opacity = '0'
        }, 400);
        setTimeout(function () {
            phoneMailingManu.current.style.height = '0'
        }, 400);
        setTimeout(function () {
            phoneMailingManu.current.style.display = 'none'
        }, 600);
    }

    function scrollConsultation(open) {
        window.scrollTo({ behavior: 'smooth', top: document.getElementById('scrollMailings').offsetTop })
        if (open === "open") {
            openManu()
        }
    }
    function scrollMailing(open) {
        window.scrollTo({ behavior: 'smooth', top: document.getElementById('freeConsultation').offsetTop })
        if (open === "open") {
            openManu()
        }
    }
    // let link = window.location.href.split('/')
    // link = link[link.length-1]
    //     if (link === '#mailings') {
    //         window.scrollTo({ behavior: 'smooth', top: document.getElementById('scrollMailings').offsetTop })                
    //     }else  if (link === '#freeConsultation') {
    //     }


    function openManu() {
        manuIcon.current.classList.toggle('manuButtonActive')
        phoneManu.current.classList.toggle('phoneManuActive')
        phoneFon.current.classList.toggle('manuFonActive')
    }
    const time = 1800; // ms
    const step = 1;

    function outNum(num) {
        let l = loaderTetx.current
        let n = 0;
        let t = Math.round(time / (num / step));
        let interval = setInterval(() => {
            n = n + step;
            if (n == num) {
                clearInterval(interval)
            }
            l.innerHTML = 'ЗАГРУЗКА ' + n + '%';
        },
            t);
    }



    function loaderFunc() {
        setInterval(function () {
            loaderLogo.current.style.opacity = '0'
            loaderTetx.current.style.opacity = '0'
            loaderImage.current.style.opacity = '0'
            loaderSpan.current.style.opacity = '0'

        }, 2000);
        setInterval(function () {
            loader1.current.style.height = '0'
            loader2.current.style.height = '0'

        }, 2300);
        setInterval(function () { loader.current.style.display = 'none' }, 3000);
        outNum(100);
    }
    useEffect(() => {
        loaderFunc()
        if (location.pathname.split('/')[1] === 'selfmailing') {
            setSelfMailingButtonClass('bottomBorder')
        }
        else if (['instagram', 'telegram', 'whatsapp', 'viber', 'vk'].indexOf(location.pathname.split('/')[1]) != -1) {
            setMailingButtonClass('bottomBorder')
        }
        else if (location.pathname.split('/')[1] === 'review') {
            setReviewButtonClass('bottomBorder')
        }
        else if (location.pathname.split('/')[1] === 'bot') {
            setBotButtonClass('bottomBorder')
        }
        else if (location.pathname.split('/')[1] === '') {
            setHomeButtonClass('bottomBorder')
        }
    }, [])


    return (
        <>
            <CookieWidget />
            <ButtonWidget />
            <ReferralWidget/>
            {/* <VideoWidget/> */}
            <div ref={loader} className="loaderDiv">
                <div ref={loaderLogo} className="baksboLogo "></div>
                <p ref={loaderTetx} className="loadingText">ЗАГРУЗКА 0%</p>
                {/* <div ref={loaderImage} className="loaderImg"></div> */}                
                <div ref={loaderImage} className="loaderAnimation">
                    <div className="loaderAnimationFon"></div>
                    <svg className="pl" viewBox="0 0 128 128" width="128px" height="128px" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id="pl-grad" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stop-color="hsl(52, 100%, 67%)" />
                                <stop offset="100%" stop-color="hsl(52, 93%, 54%)" />
                            </linearGradient>
                        </defs>
                        <circle className="pl__ring" r="56" cx="64" cy="64" fill="none" stroke="hsla(0,10%,10%,0.1)" stroke-width="16" stroke-linecap="round" />
                        <path className="pl__worm" d="M92,15.492S78.194,4.967,66.743,16.887c-17.231,17.938-28.26,96.974-28.26,96.974L119.85,59.892l-99-31.588,57.528,89.832L97.8,19.349,13.636,88.51l89.012,16.015S81.908,38.332,66.1,22.337C50.114,6.156,36,15.492,36,15.492a56,56,0,1,0,56,0Z" fill="none" stroke="url(#pl-grad)" stroke-width="16" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="44 1111" stroke-dashoffset="10" />
                    </svg>
                </div>
                <span ref={loaderSpan} >Сервис массовой рассылки сообщений</span>
                <div ref={loader1} className="loaderDiv1"></div>
                <div ref={loader2} className="loaderDiv2"></div>
            </div>
            <div className="header">
                <div className="headerNumber">
                    <p>8 (920) 966-05-91</p>
                </div>
                <div ref={manu} className="manu">
                    <div className="manuElements">
                        <p ref={homeButton} className={homeButtonClass} onClick={() => menu('homeButton')} >Главная</p>
                        <p ref={mailingButton} className={"openMailingManu " + mailingButtonClass} onClick={mailingManuToggle} >
                            <div className="mailingManuDiv">
                                <div ref={mailingManu} className="mailingManu">
                                    <p ref={instagramRef} onClick={() => menu('mailingButton', '/instagram')}>Instagram</p>
                                    <p ref={telegramRef} onClick={() => menu('mailingButton', '/telegram')}>Telegram</p>
                                    <p ref={whatsappRef} onClick={() => menu('mailingButton', '/whatsapp')}>WhatsApp</p>
                                    <p ref={viberRef} onClick={() => menu('mailingButton', '/viber')}>Viber</p>
                                    <p ref={vkRef} onClick={() => menu('mailingButton', '/vk')}>Vkontakte</p>
                                </div>
                            </div>
                            <p>Рассылка под ключ <div></div></p>
                        </p>
                        <p ref={selfMailingButton} className={selfMailingButtonClass} onClick={() => menu('selfMailingButton', '/soft')}>BAKSBO-SOFT</p>
                        <p ref={botButton} className={botButtonClass} onClick={() => menu('botButton', '/bot')} ><p>BAKSBO-BOT</p></p>
                        <p ref={reviewButton} className={reviewButtonClass} onClick={() => menu('reviewButton', '/review')} ><p>Отзывы</p></p>
                    </div>
                    <div className="manuSlider"><div className="slider"></div></div>

                    <div ref={manuIcon} onClick={openManu} className="manuButton"></div>
                    <div ref={phoneFon} onClick={openManu} className="manuFon"></div>
                    <div ref={phoneManu} className="phoneManu">
                        <div>
                            <Link onClick={() => scrollTop('open')} to='/'><p>Главная</p></Link>
                            <Link onClick={() => scrollMailing('open')} to='/#freeConsultation'><p>Консультация</p></Link>
                            <Link onClick={() => scrollTop('open')} to='/soft'><p>BAKSBO-SOFT</p></Link>
                            <Link className="reviewMobileButton" onClick={() => scrollTop('open')} to='/bot'><p>BAKSBO-BOT</p></Link>
                            <a><p onClick={mailingManuMobile}>Рассылка</p></a>
                            <div ref={phoneMailingManu} className="phoneMailingManu">
                                <Link onClick={openManu} ref={phoneMailingLink1} to={'/instagram'}> <p>Instagram</p></Link>
                                <Link onClick={openManu} ref={phoneMailingLink2} to={'/telegram'}> <p>Telegram</p></Link>
                                <Link onClick={openManu} ref={phoneMailingLink3} to={'/whatsapp'}> <p>WhatsApp</p></Link>
                                <Link onClick={openManu} ref={phoneMailingLink4} to={'/viber'}> <p>Viber</p></Link>
                                <Link onClick={openManu} ref={phoneMailingLink5} to={'/vk'}> <p>Vkontakte</p></Link>
                            </div>
                            {/* <Link onClick={openManu} to='/articles'><p>Статьи</p></Link> */}
                            <Link className="reviewMobileButton" onClick={() => scrollTop('open')} to='/review'><p>Отзывы</p></Link>
                            <Link onClick={() => scrollTop('open')} to='/contact'><p>Контакты</p></Link>
                            {user['last_name'] ?
                                <>
                                <Link onClick={() => scrollTop('open')} to='/cabinet'><p>Личный кабинет</p></Link>
                                </>
                                :
                                <>
                                <Link onClick={() => scrollTop('open')} to='/login'><p>Вход</p></Link>
                                <Link onClick={() => scrollTop('open')} to='/registration'><p>Регистрация</p></Link>                                
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="headerCabinet">
                    {user['last_name'] ?
                        <>
                        <Link to={'/cabinet'}> <p>Личный кабинет</p> </Link>
                        </>
                        :
                        <>
                        <Link to={'/registration'}> <span>Регистрация</span></Link>
                        <Link to={'/login'}> <p>Вход</p> </Link>
                        </>
                    }
                </div>
            </div>
        </>

    )
}