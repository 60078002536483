import React from 'react'
import './botBenefice.css'

const BotBenefice = () => {
    return (
        <>
            <div className='waveBotHeaderText'>
                <h1 className='botHeaderText '>УНИКАЛЬНЫЙ ГОЛОСОВОЙ БОТ НА (ИИ)</h1>
            </div>
            <div className='botBeneficeDiv'>
                <div className='bot_Wave'></div>
                <div className='bot_WaveMobile'></div>
                <div className="botBeneficeTable">
                    <div className='botBenefices'>
                        <p>Что может голосовой бот?</p>
                        <ul>
                            <li>Продать товар/услугу</li>
                            <li>Провести собеседование</li>
                            <li>Проконсультировать клиента</li>
                            <li>Рассказать о товаре/услуге</li>
                            <li>Оповестит о заказе/вебинаре</li>
                            <li>Актулизировать базу клиентов</li>
                            <li>Проинформировать о дате доставке</li>
                            <li>Принять заказ и подтвердить его актуальность</li>
                            <li>Рассказать об эксперте и подписаться на его соцсети</li>
                            <li>И многое другое</li>
                        </ul>
                    </div>
                    <div className='botBenefices'>
                        <p>Интеграция голосового бота:</p>
                        <ul>
                            <li>СМС</li>
                            <li>Bitrix24</li>
                            <li>amoCRM</li>
                            <li>SberCRM</li>
                            <li>Телефония</li>
                            <li>Другие CRM</li>
                            <li>Программы лояльности</li>
                            <li>И многое другое</li>
                        </ul>
                    </div>
                </div>
                <div className='bot_Wave'></div>
                <div className='bot_WaveMobile'></div>
            </div>

        </>
    )
}

export default BotBenefice