import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useRef, useEffect } from 'react'
import Cabinet from './cabinet/cabinet/cabinet';
import NotFoundPage from './page/notFound/notFound';
import Manu from './cabinet/elements/manu/manu'


function CabinetSite() {
    const cabinetSection = useRef(null)
    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        if (location.pathname === '/login' || location.pathname === '/registration' && localStorage.getItem('userIsAuth') === 'true') {
            navigate('/cabinet')
        }
    }, [])

    return (
        <>
            <div ref={cabinetSection} className='cabinetSection'>
                <Routes>
                    <Route path='/*' element={<Cabinet />} />
                    <Route path='*' element={<NotFoundPage />} />
                </Routes>
            </div>
            <Manu cabinetSection={cabinetSection} />
        </>
    );
}

export default CabinetSite;
