import React from 'react'
import './botCommercial.css'
import { Link } from 'react-router-dom'


const BotCommercial = () => {
    return (
        <div className='botCommercialDiv'>
            <img className='peper' src="/static/img/bot/peper.png" alt="" />
            <div className='botCommercialText'>

                <h1>Привет !</h1>
                <h1>Я Ваш личный <img src="/static/img/bot/treeLines.png" alt="" /></h1>
                <h1>BAKSBO BOT</h1>
                <img className='botCommercialLines' src="/static/img/bot/line.png" alt="" />
                <p>Обзвоню ваших нынешних клиентов <br /> или найду новых от <b>7 руб</b> за заявку. </p>
                <Link to={'/bot'}>
                    
                    Узнать больше
                    <img src="/static/img/bot/arrowLine.png" alt="" />
                    
                </Link>
            </div>
            <div className='blue_phoneDiv'>
                <img className='blue_phone' src="/static/img/bot/blue_phone.png" alt="" />

            </div>
        </div>
    )
}

export default BotCommercial