import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import InputMask from 'react-input-mask'
import axios from 'axios'
import './base.css'

const Base = () => {
    const downloadRef = useRef(null)

    const [search, setSearch] = useState('')
    const [dataCount, setDataCount] = useState(null)
    const [next, setNext] = useState('asdasd')
    const [previous, setPrevious] = useState('asdasd')
    const [startDate, setStartDate] = useState([])
    const [endDate, setEndDate] = useState([])
    const [deleteItem, setDeleteItem] = useState(null)
    const [downlaod, setDownlaod] = useState(false)
    const [data, setData] = useState([])
    // const [data, setData] = useState([
    //     {
    //         "id": 1442,
    //         "number": "79372043103",
    //         "email": "",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Елена",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-13T01:10:46+03:00",
    //         "date_update": "2023-08-15T11:34:05.782090+03:00"
    //     },
    //     {
    //         "id": 1443,
    //         "number": "79103966672",
    //         "email": "marketing@proxy.market",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Дмитрий",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-12T17:01:25+03:00",
    //         "date_update": "2023-08-15T11:34:05.793089+03:00"
    //     },
    //     {
    //         "id": 1444,
    //         "number": "79521254016",
    //         "email": "",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Иван",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-12T10:52:59+03:00",
    //         "date_update": "2023-08-15T11:34:05.804089+03:00"
    //     },
    //     {
    //         "id": 1445,
    //         "number": "79265540000",
    //         "email": "",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Теона",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-11T17:29:21+03:00",
    //         "date_update": "2023-08-15T11:34:05.819090+03:00"
    //     },
    //     {
    //         "id": 1446,
    //         "number": "79064545144",
    //         "email": "lera.gor1997@mail.ru",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Валерия",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-11T13:13:16+03:00",
    //         "date_update": "2023-08-15T11:34:05.842088+03:00"
    //     },
    //     {
    //         "id": 1447,
    //         "number": "79627069568",
    //         "email": "azat_pro@mail.ru",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Азат",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-11T08:58:17+03:00",
    //         "date_update": "2023-08-15T11:34:05.853091+03:00"
    //     },
    //     {
    //         "id": 1448,
    //         "number": "79996818214",
    //         "email": "info@lineinspace.studio",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Николай",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-11T07:06:57+03:00",
    //         "date_update": "2023-08-15T11:34:05.864087+03:00"
    //     },
    //     {
    //         "id": 1449,
    //         "number": "79951184749",
    //         "email": "fliy@mail.ru",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Андрей",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-10T12:11:31+03:00",
    //         "date_update": "2023-08-15T11:34:05.879088+03:00"
    //     },
    //     {
    //         "id": 1450,
    //         "number": "79119236770",
    //         "email": "",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Сергей",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-10T09:24:44+03:00",
    //         "date_update": "2023-08-15T11:34:05.892089+03:00"
    //     },
    //     {
    //         "id": 1451,
    //         "number": "79035099211",
    //         "email": "baby-family@mail.ru",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Елена",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-09T15:59:57+03:00",
    //         "date_update": "2023-08-15T11:34:05.906088+03:00"
    //     },
    //     {
    //         "id": 1452,
    //         "number": "79201297003",
    //         "email": "",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Илья",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-09T12:43:02+03:00",
    //         "date_update": "2023-08-15T11:34:05.916093+03:00"
    //     },
    //     {
    //         "id": 1453,
    //         "number": "79266386603",
    //         "email": "",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Кристина",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-08T09:29:53+03:00",
    //         "date_update": "2023-08-15T11:34:05.928088+03:00"
    //     },
    //     {
    //         "id": 1454,
    //         "number": "79167748952",
    //         "email": "visavusa@yandex.ru",
    //         "connect": "",
    //         "gender": "",
    //         "name": "Сергей",
    //         "post": "",
    //         "company": "",
    //         "site": "",
    //         "history": "",
    //         "date": "2022-08-06T20:59:07+03:00",
    //         "date_update": "2023-08-15T11:34:05.943087+03:00"
    //     }])

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    function formatDate(date) {
        const months = [
            'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
            'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
        ];

        const hours = date.getHours().toString().padStart(2, '0')
        const minutes = date.getMinutes().toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')
        const month = months[date.getMonth()]
        const year = date.getFullYear()

        return `${day} ${month} ${year} г. ${hours}:${minutes}`
    }
    const handleInputChange = (index, field, value, id) => {
        changeElement(id, field, value)
        const newData = [...data]
        newData[index][field] = value
        setData(newData)    
    }
    const deleteElement = (id) => {
        const csrftoken = getCookie('csrftoken')
        axios.delete(`/api/base/${id}`,
            {
                headers: {
                    'X-CSRFToken': csrftoken,
                },
            }
        ).then(response => {
            getDate('', search)
            setDeleteItem(null)
        })

    }

    const changeElement = (id, field, value) => {
        const csrftoken = getCookie('csrftoken')
        axios.patch(`/api/base/${id}`, {
            field: field,
            id: id,
            value: value,
        }, {
            headers: {
                'X-CSRFToken': csrftoken,
            },
        }
        ).then(response => {
            console.log(response.data)
        })
    }

    const getDate = (url = '', q = '') => {
        if (url === '') {
            url = `/api/bases?q=${q}&end_date=${endDate}&start_date=${startDate}`
        }
        setData([])
        axios.get(url)
        .then(response => {
                setData(response.data.results)
                setNext(response.data.next)
                setPrevious(response.data.previous)
                setDataCount(response.data.count)
            })
    }

    function download(params) {
        downloadRef.current.style.opacity = '0.3'
        axios.get('/api/download')
            .then(response => {
                if (response.data === 'success') {
                    downloadRef.current.style.opacity = '1'
                    setDownlaod(true)
                }
            })
    }

    useEffect(() => {
        getDate('', search)
    }, [search, startDate, endDate])



    return (
        <div className='crmBaseDiv'>
            {deleteItem &&
                <>
                    <div className='crmDeleteItem'>
                        <p>Удалить элемеент?</p>
                        <p onClick={() => deleteElement(deleteItem)}>Да</p>
                        <p onClick={() => setDeleteItem(null)}>Отмена</p>
                    </div>
                    <div onClick={() => setDeleteItem(null)} className="crmDeleteItemFon"></div>
                </>
            }
            <div className='crmBaseFilter'>
                <input type="text" placeholder={dataCount ? `Поиск из ${dataCount} контатов` : 'Поиск'} value={search} onChange={(e) => setSearch(e.target.value)} />

                <div className='crmBaseFilterDiv'>
                    <p>Дата:</p>
                    <span>От <input value={startDate} onChange={e => setStartDate(e.target.value)} type="date" /></span>
                    <span>До <input value={endDate} onChange={e => setEndDate(e.target.value)} type="date" /></span>

                </div>


                <div className="crmBaseNavigation">
                    {previous && <p onClick={() => getDate(previous, search)}>←</p>}
                    {next && <p onClick={() => getDate(next, search)}>→</p>}
                </div>

                <div className="crmBaseFilterDiv">
                    {!downlaod ?
                        <a ref={downloadRef} onClick={download} ><p>Подготовить данные</p></a> 
                        :
                        <Link ref={downloadRef} to={'/static/media/CRM.xlsx'} target='_blank' download>Скачать</Link>
                    }
                </div>

            </div>
            <div className='crmBaseContainer'>

                <div className='crmBaseHeader'>
                    <p>Дата</p>
                    <p>Имя</p>
                    <p>Номер</p>
                    <p>Email</p>
                    <p>Telegram</p>
                    <p>Пол</p>
                    <p>Должность</p>
                    <p>Компания</p>
                    <p>Сайт</p>
                    <p>История</p>
                </div>
                {data && data.map((item, index) => (
                    <div className='crmBase' key={index}>
                        <img onClick={() => setDeleteItem(item.id)} src="/static/img/admin/trash-can-solid.svg" alt="" />
                        <div className='crmBaseInputDiv'>
                            <span>{formatDate(new Date(item.date))}</span>
                        </div>
                        <div className='crmBaseInputDiv'>
                            <input placeholder='Имя' type="text" value={item.name} onChange={(e) => handleInputChange(index, 'name', e.target.value, item.id)} />
                        </div>
                        <div className='crmBaseInputDiv'>
                            <InputMask maskChar=" " mask="9 (999) 999-99-99" placeholder='Номер' type="text" value={item.number} onChange={(e) => handleInputChange(index, 'number', e.target.value, item.id)} />
                        </div>
                        <div className='crmBaseInputDiv'>
                            <input placeholder='Email' type="text" value={item.email} onChange={(e) => handleInputChange(index, 'email', e.target.value, item.id)} />
                        </div>
                        <div className='crmBaseInputDiv'>
                            <input placeholder='Telegram' type="text" value={item.connect} onChange={(e) => handleInputChange(index, 'connect', e.target.value ? '@' + e.target.value.replace('@', '') : e.target.value, item.id)} />
                        </div>
                        <div className='crmBaseInputSelect'>
                            <select id="gender" name="gender" value={item.gender} onChange={(e) => handleInputChange(index, 'gender', e.target.value, item.id)}>
                                <option value="">Выбрать</option>
                                <option value="male">Муж.</option>
                                <option value="female">Жен.</option>
                            </select>
                        </div>
                        <div className='crmBaseInputDiv'>
                            <input placeholder='Должность' type="text" value={item.post} onChange={(e) => handleInputChange(index, 'post', e.target.value, item.id)} />
                        </div>
                        <div className='crmBaseInputDiv'>
                            <input placeholder='Компания' type="text" value={item.company} onChange={(e) => handleInputChange(index, 'company', e.target.value, item.id)} />
                        </div>
                        <div className='crmBaseInputDiv'>
                            <input placeholder='Сайт' type="text" value={item.site} onChange={(e) => handleInputChange(index, 'site', e.target.value, item.id)} />
                        </div>
                        <textarea placeholder='История' value={item.history} onChange={(e) => handleInputChange(index, 'history', e.target.value, item.id)} ></textarea>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Base