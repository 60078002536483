import React from 'react'

const EducationPoint = (args) => {
    return (
        <div className={`educationPoint educationPoint${args.data.count}`}>
            <p className='educationPointHeader educationPointHeaderAbsolute'>
                <p><h2>{args.data.count}</h2> день <br /></p>
                {args.data.count > 3 ? "ПРАКТИКА" : "ОБУЧЕНИЯ"}
            </p>
            <div className='educationPointInfo'>
                <div className="educationPointInfoHeader">
                    <p className='educationPointHeader'>
                        <p><h2>{args.data.count}</h2> день <br /></p>
                        {args.data.count > 3 ? "ПРАКТИКА" : "ОБУЧЕНИЯ"}
                    </p>
                    <p className='educationPointTime'>
                        <h4>
                            <img src="/static/img/bot/watch.png" alt="" />
                            {args.data.time}
                        </h4>
                        <h5>
                            длительность
                        </h5>
                    </p>
                </div>
                <ul>
                    {args.data.list.map(txt =>
                        <li>{txt}</li>
                    )}
                </ul>
            </div>
        </div>
    )
}

export default EducationPoint