import { Link } from "react-router-dom";

export default function Header() {

    function scrollConsultation(open) {
        window.scrollTo({ behavior: 'smooth', top: document.getElementById('scrollMailings').offsetTop })
        if (open === "open") {
        }
    }
    function scrollMailing(open) {
        window.scrollTo({ behavior: 'smooth', top: document.getElementById('freeConsultation').offsetTop })
        if (open === "open") {
        }
    }
    return (
        <div className="footer">
            <div className="topFooter">
                <div className="footerTopManu">
                    <Link to='/'><p>Главная</p></Link>
                    <Link onClick={() => scrollMailing('open')} to='/#freeConsultation'><p>Консультация</p></Link>
                    <Link onClick={() => scrollConsultation('open')} to='/#mailings'><p>Рассылка</p></Link>
                    {/* <Link to='/articles'><p>Статьи</p></Link> */}
                    <Link to='/review'><p>Отзывы</p></Link>
                    <Link to='/contact'><p>Контакты</p></Link>
                </div>
            </div>
            <div className="midFooter">
                <div className="footerIINInfo">
                    <p>ИП Исаев Николай Николаевич</p>
                    <p>ИНН: 622907207610</p>
                    <p>ОГРНИП: 323623400016963</p>
                    <p>Адрес: г. Рязань ул. Ситниковская д. 69А офис 27</p><br />
                </div>
                <div className="footerSocialLinks">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/baksbo.ru/">Instagram</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">Telegram</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">WhatsApp</a>
                    <a target="_blank" rel="noopener noreferrer" href="https://vk.com/baksbo_ru">Vkontakte</a>
                    
                </div>

            </div>
            <div className="bottomFooter">
                <p>
                    Все права защищены © 2020-{new Date().getFullYear()}. <br />
                    Весь материал, размещённый на сайте имеет авторские права. <br />
                    Подтверждение авторства в Яндекс.Вебмастер. <br />
                    Подтверждение авторства в Google Search Console. <br />
                </p>

                <div>
                    <a target="_blank" rel="noopener noreferrer" href="static/files/oferta.pdf">Публичная оферта</a>
                    <a target="_blank" rel="noopener noreferrer" href="static/files/privacy_policy.pdf">Политика конфиденциальность</a>
                    <a target="_blank" rel="noopener noreferrer" href="static/files/cabinet_oferta.pdf">Условия предоставления сервиса</a>
                    <a target="_blank" rel="noopener noreferrer" href="static/files/privacy_policy_bot.pdf">Пользовательское соглашение BAKSBO-BOT</a>
                </div>
            </div>            
        </div>
    )
}