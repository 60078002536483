import React, { useState } from 'react'
import BotTarifTable from './botTarifTable'
import { Link } from 'react-router-dom'
import './botTarifs.css'
import Support from '../support/support'

const BotTarifs = () => {
    const [active, setActive] = useState(false)
    return (
        <>
            <div className="botTarifsDiv" id='bot_tarifs'>

                <h1 className="botHeaderText">
                    Стоимость разработки
                </h1>
                <div className='botTarif'>
                    <div>
                        <h1>46<h6>% </h6> <h5> СКИДКА</h5></h1>
                        <h4>на разработку от <i> 54 000 руб.</i></h4>
                        <p>от 29000 руб </p>
                        <span>Оплачивайте любым для вас удобным способом </span>
                        <h2>60/40 или в рассрочку</h2>
                    </div>
                    <a onClick={() => setActive(true)}>
                        Увеличить свою прибыль
                        <img src="/static/img/bot/arrowLine.png" alt="" />
                    </a>
                </div>
                <img className='phoneeithwifi' src="/static/img/bot/botik.png" alt="" />
                <div className="botTarifElipse"><img src="/static/img/bot/coins.png" alt="" /></div>
                <div className="botTarifElipse botTarifElipse_2"><img src="/static/img/bot/coin.png" alt="" /></div>
                <img className='vectorLine' src="/static/img/bot/vectorLine.png" alt="" />
                <h3>*Точная сумма разработки зависит от сложности бота и способа оплаты, подробней можете проконсультироваться у нашего менеджера по телефону или оставив заявку по кнопке выше.</h3>
            </div>

            <Support active={active} category='Стоимость разработки' setFunc={setActive}/>
        </>
    )
}

export default BotTarifs