import React from 'react'
import './staticCase.css'

const StatisticCase = () => {
  return (
    <div className='selfMailingPageBodyStatistic selfMailingPageBodyStatisticTop'>
                <h1 className='selfMailingPageBodyHeader'>Сотни довольных клиентов</h1>
                <p className='selfMailingPageBodyDescription'>Реальные истории о проделаной работе от <b> BAKSBO.RU</b></p>
                <div className="selfMailingPageBodyStatisticInfo">
                    <div>
                        <p>270 млн</p>
                        <div className='line'></div>
                        <span>Разосланных сообщений</span>
                    </div>
                    <div>
                        <p>250 стран</p>
                        <div className='line'></div>
                        <span>Мы работаем по всему миру</span>
                    </div>
                    <div>
                        <p>210+</p>
                        <div className='line'></div>
                        <span>Отзывов от клиентов</span>
                    </div>
                </div>
            </div>
  )
}

export default StatisticCase