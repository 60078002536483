import React, { useRef, useState, useEffect } from 'react'
import './referralWidget.css'

const ReferralWidget = () => {
  const referralWidget = useRef(null)
  const fon = useRef(null)
  function close() {
    fon.current.classList.add('closed')
    referralWidget.current.classList.add('referralWidgetDivClosed')
    const date = new Date()
    localStorage.setItem('date', date)
  }
  function open() {
    setTimeout(() => {
      fon.current.classList.remove('closed')
      referralWidget.current.classList.remove('referralWidgetDivClosed')
      }, 15000);
  }

  useEffect(() => {
      if (localStorage.getItem('date')) {
        const date = new Date()
        const localDate = new Date(localStorage.getItem('date'))
        const timeDiff = Math.round((date - localDate) / 60000)
        // if (true) {
          if (timeDiff > 1440){
            open()
          }
        }
        else {
        open()
      }    
  }, [])

  return (
    <>

      <div ref={referralWidget} className='referralWidgetDiv referralWidgetDivClosed'>
        <img src="/static/img/referral_icon.png" alt="" />
        <div onClick={close} className='referralWidgetCloseDIv'></div>
        <div className='referralWidgetInfoDiv'>
          <h1>Станьте нашим партнером или рекомендуйте своим друзьям!</h1>
          <h2>Получайте от 5% до 15% выплат в рублях от общей суммы заказа на свою карту в тот же день.</h2>
          <span>Подключится или уточнить информацию можно тут</span>
          <div>

            <a target="_blank" rel="noopener noreferrer" href="https://t.me/baksbo_ru">
              <img src="static/img/icons/tg.png" alt="" />
              Telegram
            </a>

            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/+789209660591">
              <img src="static/img/icons/waapp.png" alt="" />
              WhatsApp
            </a>
            <a rel="noopener noreferrer" href="tel:89209660591">
              8 (920) 966-05-91
            </a>
          </div>
        </div>
      </div>
      <div ref={fon} onClick={close} className='referralWidgetFon closed'></div>
    </>
  )
}

export default ReferralWidget