import './style.css'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'

export default function Bounus() {
  useEffect(() => {
    Aos.init({ duration: 2500 })

}, [])

    return (
        <>
          <div className='bonusDiv'>
            <div className="bonusText workText">
                    <h2> + бонусом</h2>
                    <h1>Поможем написать текст</h1>
                    <div className='workLine'></div>
                    <p><i>МАКСИМАЛЬНО ПОВЫСИМ КОНВЕРСИЮ</i></p>
                    <span>Наш <i>модератор поможет вам написать текст для рассылки,</i> отталкиваясь от вашего основного чернового текста, он отредактирует текст максимально эффективно для поднятия конверсии отклика на текст. <i>Итоговый текст будет согласовываться с вами.</i> Каждый текст проходит уникальную модерацию с индивидуальным подходом и не редактируется по стандартным шаблонам.</span>
            </div>
            <div data-aos="fade-up" className="bonusImage">
                <div></div>
                <img src="static/img/bonus.png" alt="" />
            </div>
          </div>
        </>
    )
}