import React, { useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/skyblue';
import '@splidejs/react-splide/css/core';
import '@splidejs/react-splide/css/sea-green';
import './botMarketing.css'


const BotMarketing = () => {
    const [sliderData, setSliderData] = useState({
        rewind: true,
        perPage: 1,
        focus: 0,
        omitEnd: true,
        type: 'loop',
        autoplay: true,
    })
    const list = [
        { 'header': 'Для офлайн или онлайн мероприятий', 'list': ['Рассказать о мероприятии', 'Напомнить о мероприятии', 'Предложить выслать больше информации', 'Предложить выслать ссылку для регистрации', 'Собрать обратную связь по окончанию мероприятий'] },
        { 'header': 'Для владельцев интернет-магазинов', 'list': ['Расскажет о товаре, продаст его', 'Оформит заказ и подтвердит его актуальность', 'Проинформирует о датах доставки'] },
        { 'header': 'Для сферы общепита', 'list': ['Предложит прейскурант', 'Оформить заказ', 'Проинформирует о готовности', 'Актуализирует базу клиентов'] },
        { 'header': 'Для малого и среднего бизнеса', 'list': ['Предоставляет первичную консультацию', 'Свяжет с реальным специалистом, если ситуация сложная', 'Назначит и подтвердит встречу', 'Проведет собеседование'] },
        { 'header': 'Для инфобизнеса', 'list': ['Предложить бесплатную консультацию или разбор • Выслать ссылки', 'Предложить подписаться на свои ресурсы (Telegram, Instagram, ВК)', 'Лично вами озвучить бота, для более лояльного контакта с клиентом'] },
    ]

    return (
        <>
            <h1 className='botHeaderText'>
                Голосовой бот выполнит <i>любые задачи вашего бизнеса</i>, <br />
                воплощайте все, что вам нужно!                
            </h1>
            <div className='botMarketingDiv'>
                {list.map(item => {
                    return (
                        <div className='botMarketingItem'>
                            <a href="">
                                <img src="/static/img/bot/arrow.png" alt="" />
                            </a>
                            <p>{item['header']}</p>
                            <span>Голосовой бот:</span>
                            <ul>
                                {item['list'].map(li => {
                                    return (
                                        <li>{li}</li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}


            </div>
            <div className='botMarketingSlide'>
                <Splide
                    options={sliderData} >
                    {list.map(item => {
                        return (
                            <SplideSlide>
                                <div className='botMarketingKase'>
                                    <p>{item['header']}</p>
                                    <span>Голосовой бот:</span>
                                    <ul>
                                        {item['list'].map(li => {
                                            return (
                                                <li>{li}</li>
                                            )
                                        })}
                                    </ul>

                                </div>
                            </SplideSlide>
                        )
                    })}
                </Splide>
            </div>
        </>
    )
}

export default BotMarketing