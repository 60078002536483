import React from 'react'
import './manVSbot.css'

const ManVSbot = () => {
    return (
        <>
            <h1 className='botHeaderText'>
                Почему будущее за<br /> <i>голосовыми ботами?</i>
            </h1>
            <div className="manVSbotdiv">
                <div className='manVSbotVS'>
                    <img src="/static/img/bot/vs.png" alt="" />
                </div>
                <div className='manDiv'>
                    <img height={'100%'} width='100%' className='mobileSizes mobileSizesMan' src="/static/img/bot/manDiv.png" alt="" />
                    <p>Человек</p>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Требует постоянного обучения и больших затрат на повышение продуктивности сотрудника</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Постоянный поиск сотрудника</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Настроение (может нагрубить или не тактично общаться с клиентом)</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Риски не выход на работу</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Делает 2 звонка в минуту</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>8 - часовой рабочий день <br /> 1 час обеда = 10 потерянных клиентов</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Работает по скрипту, не улучшая его</span> </div>                    
                    <img className='manDivImg' src="/static/img/bot/man.png" alt="" />
                </div>
                <div className='botDiv'>
                    <img height={'100%'} width='100%' className='mobileSizes mobileSizesBot' src="/static/img/bot/botDiv.png" alt="" />
                    <p>Голосовой бот:</p>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Делает 100 звонков в минуту</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Контакт с вашими клиентами 24/7 в удобном для них формате</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Улучшает скрипт продаж после каждого звонка</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Запуск звонков в любое время дня и ночи</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Легко перенастраивается и сразу готов к работе</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Стабильность работы без каких либо технических проблем</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Стрессоустойчивость к разговорам по холодной аудитории</span> </div>                    
                    <div><img src="/static/img/bot/ptichka.png" alt="" /> <span>Работает строго по скрипту без нервов и волнения</span> </div>                    
                    <img className='botDivImg' src="/static/img/bot/bot1.png" alt="" />                    
                </div>
            </div>
        </>
    )
}

export default ManVSbot